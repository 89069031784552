export const userSuggestion = [
    {
        id: 1,
        name: "Elon Musk",
        username: "elonmusk",
        image: "https://pbs.twimg.com/profile_images/1590968738358079488/IY9Gx6Ok_400x400.jpg",
    },
    {
        id: 2,
        name: "Justin Kan",
        username: "justintv",
        image: "https://pbs.twimg.com/profile_images/1630623327156056064/dpULA8BX_400x400.jpg",
    },
    {
        id: 3,
        name: "Guillermo Rauch",
        username: "rauchg",
        image: "https://pbs.twimg.com/profile_images/1576257734810312704/ucxb4lHy_400x400.jpg",
    },
    {
        id: 4,
        name: "Brain Chesky",
        username: "bchesky",
        image: "https://pbs.twimg.com/profile_images/1588160866733563904/5K2N7y0h_400x400.jpg",
    },
];